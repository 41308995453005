header{
	background-image: url('../images/nav-bar@2x.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 90px;
	@include media-breakpoint-down(md) {
		height: 70px;
	}
	.interior &{
		background-image: url('../images/nav-bar@2x.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.planes.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.centros.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.logo{
		/*@include media-breakpoint-down(md) {
			width: 120px;
		}*/
	}

	.menu{
		padding:0;
		text-align: center;
		li{
			margin-left: 10px;
			list-style:none;
			display: inline-block;
			@include fontSize($body-size-s);
			a{
				color:white;
				&:hover{
					@extend .fuente-title;
					color:#01A875;
					text-decoration: underline;
				}
			}
			&.active{
				a{
					text-decoration: none;
					color:#01A875;
				}
			}
		}
	}

}
